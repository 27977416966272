import React from 'react';
// import React, { useState } from 'react';
import Footer from './Footer';
import './Landing.css';
import './landing-sass.scss';
import './landing-bg.scss';
// import { Modal } from 'react-bootstrap';
// import { MdGTranslate, MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";


function Landing() {

    const nav_logo = require("../images/logo1.png");
    const downloadapp_1 = require("../images/gplay.png");
    const downloadapp_2 = require("../images/appstore.png");

    // modal
    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    return (

        <>
            <div className="wrap-landing">
                <nav className="nav-wrapper">
                    <div className="nav-container-1">
                    <a href="/" target="_parent"><img src={nav_logo} alt="Macao 2D/3D" /></a>
                    </div>
                    <div className="nav-container-2">
                        {/* <div href="/" className="nav-content-lang" onClick={handleShow}>
                            <div className="nav-content-links">
                                <MdGTranslate />
                                <p>Language</p>
                            </div>
                        </div > */}
                        <a href="/privacy-policy" className="nav-content-priv">
                            <div className="nav-content-links">
                                <span aria-hidden="true" data-icon="&#xe914;" className="navlink-icon-1"></span>
                                <p>Privacy Policy</p>
                            </div>                        
                        </a>
                        
                    </div>
                </nav>

                <div className="wrap-land-body">
                <div class="page-bg"></div>
                    <div class="animation-wrapper">
                        <div class="particle particle-1"></div>
                        <div class="particle particle-2"></div>
                        <div class="particle particle-3"></div>
                        <div class="particle particle-4"></div>
                    </div>
                    <div className="landbody-container">
                        {/* <div className="waviy">
                            <span>2</span>
                            <span>D</span>
                            <span>3</span>
                            <span>D</span>
                        </div> */}
                        <p className="lb-content-p-1" >Check LIVE 2D/3D Result with</p>
                        {/* <h1 className="lb-content-h1" title="MACAO" >MACAO <span></span></h1> */}
                        <div className="content">
                            <h1 className="lb-content-h1">MACAO <span className="text_shadows"></span></h1>
                        </div>
                        <h1 className="lb-content-h1-mb" >MACAO 2D3D</h1>
                        <p className="lb-content-p-2" >We have launched our Macao2D3D App on both Google Play & App Store</p>
                        <div className="lb-download-app">
                            <a href="https://play.google.com/store/apps/details?id=com.macao2d3d.app" target="_blank" rel="noopener noreferrer" ><img src={downloadapp_1} alt="Macao 2D3D Google Play Store Download" /></a>
                            <a href="https://apps.apple.com/app/macao-2d3d/id1628487021" target="_blank" rel="noopener noreferrer" ><img src={downloadapp_2} alt="Macao 2D3D App Store Download" /></a>
                        </div>
                        
                    
                    </div>
                    
                </div>

                <Footer />
            </div>


            
            {/* <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title><h4>Select Language</h4></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <a href="/" className="lang-select">
                        <p className="lang-option-1">English</p>
                        <MdRadioButtonChecked />
                    </a>
                    <a href="/zh" className="lang-select">
                        <p className="lang-option-2">简体中文</p>
                        <MdRadioButtonUnchecked />
                    </a>
                </Modal.Body>
            </Modal> */}
        
        </>

    );

}

export default Landing;