import React from 'react';
import './Footer.css';


function Footer() {


    return (

        <>
            <footer className="wrap-footer">

                <div className="footer-container">
                    <p>© 2022 . Macao2D3D . <br/> All Rights Reserved</p>
                </div>

            </footer>
        
        </>

    );

}

export default Footer;