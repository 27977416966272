import React from 'react';
import '../../App.css';
import Landing from '../Landing';

function Home() {
    return (
        <> 
            <Landing />
        </>
    ); 
}

export default Home;