import React, { useEffect }  from 'react';
import '../../App.css';
import PrivacyContent from '../PrivacyContent';

function PrivacyPage() {

    useEffect(() => {
        document.title = "Privacy Policy - Macao 2D3D";  
    }, []);

    return (
        <> 
            <PrivacyContent />
        </>
    ); 
}

export default PrivacyPage;