import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';    /* Switch = Routes in react-router-dom v6 */

import Home from './components/pages/Home';
// import HomeCN from './components/pages/Home_CN';

import NotFoundPage from './components/pages/NotFoundPage';
import PrivacyPage from './components/pages/PrivacyPage';
// import PrivacyPageCN from './components/pages/PrivacyPage_CN';


function App() {
  return (
    <>
    <Router>

      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/zh" element={<HomeCN />} /> */}

        <Route path="/privacy-policy" element={<PrivacyPage />} />
        {/* <Route path="/zh/privacy-policy" element={<PrivacyPageCN />} /> */}
        
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      
    </Router>
    
    </>
  );
}

export default App;
